import { gql } from "@apollo/client";

export const UPDATE_ABSENCE_REASON = gql`
  mutation updateAbsenceReason(
    $center: ID!
    $title: String!
    $absenceReason: ID!
    $color: String
  ) {
    updateAbsenceReason(
      center: $center
      title: $title
      absenceReason: $absenceReason
      color: $color
    ) {
      payload {
        _id
      }
    }
  }
`;
