import React, { useCallback, useState } from "react";
import PageHeader from "UI/PageHeader";
import InnerLoader from "components/InnerLoader";
import { useAppSelector } from "hooks/redux";
import { useQuery } from "@apollo/client";
import { GET_DEBTORS_BY_MONTH } from "./api";
import TeachersSelect from "components/TeachersSelect";
import { useForm } from "react-hook-form";
import { DebtorGroupType } from "./types";
import {
  Alert,
  Button,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import formatStudentAttendancesAndPayments, {
  formatDebtorsMonths,
  getSummaryMonths,
  getTotalDebtsAmount,
} from "./helpers";
import t from "translate";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { DebtorStyle } from "./Debtors.style";
import EmptyDebtors from "./components/NotDebtsState";
import { displayPhoneFormatter } from "UI/PhoneInput/helper";

const border = "1px solid rgba(224, 224, 224, 1)";

function Debtors() {
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const centerId = useAppSelector((state) => state.center.center?._id);

  const { data, loading } = useQuery(GET_DEBTORS_BY_MONTH, {
    variables: {
      center: centerId,
      teacher: selectedTeacher,
    },
    fetchPolicy: "network-only",
  });

  const groups: DebtorGroupType[] = data?.getDebtorsByMonth?.payload || [];

  const {
    control,
    formState: { errors },
  } = useForm();

  const handlePrint = useCallback(() => {
    const printContent = document.getElementById("debtors");
    const printWindow = window.open(
      "#",
      "",
      "width=1000,height=600,left=200,top=200"
    );

    printWindow?.document.write("<html><head><title>Print debtors</title>");
    printWindow?.document.write(DebtorStyle);
    printWindow?.document.write("</head><body>");
    printWindow?.document.write(printContent?.innerHTML || "");
    printWindow?.document.write("</body></html>");

    printWindow?.document.close();
    printWindow?.focus();
    printWindow?.print();
    printWindow?.close();
  }, []);

  const monthsArray = Array.from(getSummaryMonths(groups).values());
  const months = formatDebtorsMonths(monthsArray);

  return (
    <>
      <PageHeader title="Debtors">
        <Button
          variant="outlined"
          disabled={loading || !groups.length}
          onClick={handlePrint}
        >
          <LocalPrintshopOutlinedIcon />
        </Button>
        <TeachersSelect
          control={control}
          errors={errors}
          name="teacher"
          onSelect={setSelectedTeacher}
          isFullWith={false}
        />
      </PageHeader>
      {loading && <InnerLoader />}

      {!!groups.length && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {t("Displayed are the students who have debts exceeding 10,000 uzs.")}
        </Alert>
      )}
      <div id="debtors">
        {!!groups.length && (
          <Paper variant="outlined" sx={{ mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableRow>
                  <TableCell align="center" colSpan={12} component="th">
                    {t("Debtors summary")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("group")}</TableCell>
                  {months.map((date: string) => {
                    return (
                      <TableCell
                        sx={{
                          borderLeft: border,
                          borderRight: border,
                        }}
                        component="th"
                      >
                        {date}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {groups.map(({ title, _id, students }) => {
                  const formattedDebts = getTotalDebtsAmount(students);

                  return (
                    <TableRow key={_id}>
                      <TableCell
                        sx={{
                          borderLeft: border,
                          borderRight: border,
                        }}
                      >
                        {title}
                      </TableCell>
                      {months.map((month) => {
                        const { expectedAmount, paidAmount } = formattedDebts[
                          month
                        ] || { expectedAmount: 0, paidAmount: 0 };
                        return (
                          <TableCell
                            sx={{
                              borderLeft: border,
                              borderRight: border,
                            }}
                          >
                            {(expectedAmount - paidAmount).toLocaleString(
                              "fi-FI",
                              { maximumFractionDigits: 0 }
                            )}{" "}
                            uzs
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </Table>
            </TableContainer>
          </Paper>
        )}
        {!!groups.length &&
          groups.map(({ title, _id, students }) => {
            const formattedPaymentsAndAttendances =
              formatStudentAttendancesAndPayments(students);
            const months = formatDebtorsMonths(
              Object.keys(formattedPaymentsAndAttendances)
            );

            return (
              <Paper variant="outlined" sx={{ mb: 2 }} key={_id}>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }}>
                    <TableRow key={_id}>
                      <TableCell colSpan={8} align="center" component="th">
                        {title}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">{t("student")}</TableCell>
                      {months.map((month) => (
                        <TableCell
                          sx={{
                            borderLeft: border,
                            borderRight: border,
                          }}
                          component="th"
                          key={month + _id}
                        >
                          {month}
                        </TableCell>
                      ))}
                    </TableRow>
                    {students.map((groupStudent) => {
                      const { student, user, _id } = groupStudent;

                      return (
                        <TableRow key={_id}>
                          <TableCell sx={{ maxWidth: 150 }}>
                            <Typography
                              className="student-name"
                              variant="subtitle2"
                            >
                              {student?.name || user?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="student-phone"
                              color="GrayText"
                              mt={0.8}
                            >
                              {displayPhoneFormatter(
                                user?.phone || student?.phone || ""
                              )}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="student-phone"
                              color="GrayText"
                              mt={0.8}
                            >
                              {displayPhoneFormatter(student?.extraPhone || "")}
                            </Typography>
                          </TableCell>
                          {months.map((month) => {
                            const { expectedAmount, paidAmount } =
                              formattedPaymentsAndAttendances[month]?.[_id] ||
                              {};

                            if (!expectedAmount && !paidAmount) {
                              return (
                                <TableCell
                                  className="empty-debt-month"
                                  align="center"
                                  sx={{
                                    bgcolor: "#f9f9f9",
                                    borderLeft: border,
                                    borderRight: border,
                                  }}
                                  key={month + _id}
                                >
                                  --
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell
                                sx={{
                                  borderLeft: border,
                                  borderRight: border,
                                }}
                                key={month + _id}
                              >
                                <Stack
                                  className="debt-info__wrapper"
                                  mb={1}
                                  gap={0.4}
                                  direction="row"
                                  flexWrap="wrap"
                                >
                                  <Typography variant="body2" color="GrayText">
                                    {t("Total expected amount")}:
                                  </Typography>
                                  <Typography variant="body2" fontWeight={500}>
                                    {expectedAmount?.toLocaleString("fi-FI", {
                                      maximumFractionDigits: 0,
                                    })}{" "}
                                    uzs
                                  </Typography>
                                </Stack>
                                <Stack
                                  className="debt-info__wrapper"
                                  mb={1}
                                  gap={0.4}
                                  direction="row"
                                  flexWrap="wrap"
                                >
                                  <Typography variant="body2" color="GrayText">
                                    {t("Paid amount")}:
                                  </Typography>
                                  <Typography variant="body2" fontWeight={500}>
                                    {paidAmount?.toLocaleString("fi-FI", {
                                      maximumFractionDigits: 0,
                                    })}{" "}
                                    uzs
                                  </Typography>
                                </Stack>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Paper>
            );
          })}
      </div>
      {!groups.length && !loading && <EmptyDebtors />}
    </>
  );
}

export default Debtors;
