import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import ActionCard from "components/ActionCard";
import { usePermission } from "hooks/permission";
import React, { useCallback, useEffect, useState } from "react";
import { DELETE_ROADMAP } from "./api";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import t from "translate";
import { useNavigate } from "react-router-dom";
import paths from "appConstants/RoutePaths";
import UpdateRoadmap from "./components/Update";

type Props = {
  roadmapId: string;
  center: string;
  title: string;
  description: string;
  refetch: any;
};

function Actions({ center, roadmapId, title, description, refetch }: Props) {
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [deleteRoadmap, { data, loading }] = useMutation(DELETE_ROADMAP);

  const { PermissionComponents } = usePermission();
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteRoadmap({
      variables: {
        center,
        roadmapId,
      },
    });
  };

  const toggleUpdateModal = useCallback(() => {
    setIsUpdateModalVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Roadmap deleted successfully!"));
      navigate(paths.CENTER_ROADMAPS);
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Box display="grid" gap={2}>
        <PermissionComponents section="roadmaps" action="edit">
          <ActionCard
            type="warning"
            btnLabel="edit"
            title="Update roadmap"
            onClick={toggleUpdateModal}
            description="update roadmap title for make changes."
            isButtonDisabled={!roadmapId}
          />
        </PermissionComponents>
        <PermissionComponents section="roadmaps" action="delete">
          <ActionCard
            type="danger"
            btnLabel="delete"
            title="Delete roadmap"
            onClick={handleDelete}
            description="If you delete the roadmap, all of the related subjects and topics will also be deleted."
            isButtonDisabled={!roadmapId}
          />
        </PermissionComponents>
      </Box>
      <UpdateRoadmap
        _id={roadmapId}
        center={center}
        title={title}
        description={description}
        refetch={refetch}
        onClose={toggleUpdateModal}
        open={isUpdateModalVisible}
      />
    </>
  );
}

export default Actions;
