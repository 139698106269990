import { colors } from "@mui/material";
import styled from "styled-components";

const GroupAbsenceStyle = styled.div`
  .miss-lesson-group {
    background-color: ${colors.red[50]};
  }
`;

export default GroupAbsenceStyle;
