import React, { useCallback, useState } from "react";

import t from "translate";
import { Button, Grid, Pagination } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useAppSelector } from "hooks/redux";

import NoData from "UI/NoData";
import PageHeader from "UI/PageHeader";
import Loader from "components/Loader";
import { DiscountType } from "./types";
import { GET_PAGINATED_DISCOUNTS } from "./api";
import DiscountCard from "./components/DiscountCard";
import CreateDiscountModal from "./components/CreateModal";

import { usePermission } from "hooks/permission";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";

function Discounts() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const { PermissionComponents } = usePermission();

  const { data, loading, refetch } = useQuery(GET_PAGINATED_DISCOUNTS, {
    variables: { center: centerId, page: currentPage },
  });

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen((prev) => !prev);
  }, []);

  const discounts: DiscountType[] = data?.getPaginatedDiscounts?.payload || [];
  const totalPages: number = data?.getPaginatedDiscounts?.totalPages || 1;

  return (
    <>
      <PageHeader title="Discounts">
        <PermissionComponents section="discounts" action="create">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={toggleCreateModalOpen}
          >
            {t("Create discount")}
          </Button>
        </PermissionComponents>
      </PageHeader>
      {loading && <Loader />}
      {!discounts.length && <NoData />}
      <Grid container spacing={2}>
        {discounts?.map(({ _id, title, percentage }) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DiscountCard
              key={_id}
              _id={_id}
              title={title}
              refetch={refetch}
              center={centerId || ""}
              percentage={percentage}
            />
          </Grid>
        ))}
      </Grid>
      {discounts.length && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
        />
      )}
      {isCreateModalOpen && (
        <CreateDiscountModal
          open={isCreateModalOpen}
          refetch={refetch}
          onClose={toggleCreateModalOpen}
        />
      )}
    </>
  );
}

export default Discounts;
