import { gql } from "@apollo/client";

export const CREATE_ABSENCE_REASON = gql`
  mutation createAbsenceReason($center: ID!, $title: String!, $color: String) {
    createAbsenceReason(center: $center, title: $title, color: $color) {
      payload {
        _id
      }
    }
  }
`;
