export function pxToRem(px: number): string {
  return px / 16 + "rem";
}

export const acceptedFilesType = ["image/jpg", "image/png", "image/jpeg"];

export const monthsLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TimeFormat = "HH:mm";
export const DateFormat = "DD.MM.YYYY";
export const DateAndTimeFormat = "DD.MM.YYYY HH:mm";
export const DayFormat = "ddd";

export const removeTypeName: any = (value: any) => {
  if (typeof value !== "object" || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((val) => removeTypeName(val));
  }

  const keys = Object.keys(value);
  const res: any = {};
  keys.forEach((key: string) => {
    if (key !== "__typename") {
      res[key] = removeTypeName(value[key]);
    }
  });
  return res;
};
