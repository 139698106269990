export const permissionSections = `
  sections {
    info {
      view
      edit
    }
    dashboard {
      view
    }
    admission {
      view
      edit
    }
    courses {
      view
      edit
      create
      addGroup
    }
    groups {
      view
      viewInfo
      viewAction
      viewFullStudentInfo
      viewStudent
      viewPayments
      create
      addStudent
      removeStudent
      sessions
      editSession
      movePayment
    }
    students {
      view
      viewInfo
      add
      remove
    }
    employees {
      view
      add
      remove
    }
    payments {
      view
      create
      edit
      delete
    }
    debtors {
      view
    }
    salaryTemplates {
      view
      create
      edit
      delete
    }
    salaryPayments {
      view
      edit
      create
      delete
    }
    salaryAdvances {
      view
      edit
      create
      delete
    }
    schedule{
      view
    }
    expenses {
      view
      edit
      create
      delete
    }
    employeeAttendance {
      view 
      attendance
    }
    studentAbsences {
      view
      contact
    }
    groupAbsences {
      view
    }
    absenceReasons {
      view
      create
      delete
      edit
    }
    sessions {
      view
    }
    roadmaps {
      view 
      create
      delete
      edit
    }
    subjects {
      view 
      create
      delete
      edit
    }
    topics {
      view 
      create
      delete
      edit
    }
    rooms {
      view
      edit
      delete
      create
    }
    sms {
      view
      send
      viewTemplate
      editTemplate
      deleteTemplate
      createTemplate
    }
    discounts {
      view
      edit
      delete
      create
    }
    settings{
      view
    }
  }
`;
