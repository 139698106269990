import React from "react";
import { StyledSidebar } from "./Style";
import { useLocation } from "react-router-dom";
import { TChildren } from "interfaces";
import { usePermission } from "hooks/permission";
import t from "translate";
import { Stack, Typography } from "@mui/material";
import Container from "UI/Container";
import { useAppSelector } from "hooks/redux";
import SIDEBAR_ROUTES from "./constants";
import { formatSidebarLinks } from "./helpers";
import SidebarLink from "./components/SidebarLink";

interface IProps {
  children: TChildren | any;
}

const Sidebar = ({ children }: IProps) => {
  const { pathname } = useLocation();
  const { PermissionRoutes } = usePermission();

  const userRole = useAppSelector((state) => state.center.permission?.role);

  if (!pathname.includes("/center/")) {
    return children;
  }

  const allowedRoutes = PermissionRoutes(SIDEBAR_ROUTES);

  if (userRole === "visitor") {
    return <Container>{children}</Container>;
  }
  const formattedRoutes = formatSidebarLinks(allowedRoutes);
  return (
    <StyledSidebar>
      <Stack direction="row">
        <Stack className="sidebar">
          {formattedRoutes.map(([stack, routes]) => (
            <div className="sidebar__link-stack-wrapper" key={stack}>
              <Typography variant="subtitle2" className="sidebar__stack-title">
                {t(stack)}
              </Typography>
              <ul>
                {routes.map((route) => {
                  return (
                    <SidebarLink
                      route={route}
                      key={route.key}
                      pathname={pathname}
                    />
                  );
                })}
              </ul>
            </div>
          ))}
        </Stack>
        <div className="sidebar__child-side">
          <Container>{children}</Container>
        </div>
      </Stack>
    </StyledSidebar>
  );
};
export default React.memo(Sidebar);
