import { gql } from "@apollo/client";

const GET_GROUPS_ABSENCE = gql`
  query getMissedLessonGroups($center: ID!, $date: String!) {
    getMissedLessonGroups(center: $center, date: $date) {
      payload {
        _id
        title
        price
        days
        currentSubject {
          title
        }
        room {
          title
        }
        startTime
        endTime
        teacher {
          name
          image
          phone
        }
      }
    }
  }
`;

export default GET_GROUPS_ABSENCE;
