import { ISidebarRoute } from "interfaces/Route";

export const formatSidebarLinks = (
  routes: ISidebarRoute[]
): [string, ISidebarRoute[]][] => {
  let temporaryRoutes = routes;
  const formattedRoutes: [string, ISidebarRoute[]][] = [];
  for (const element of temporaryRoutes) {
    const foundStackRoutes = temporaryRoutes.filter(
      ({ stack }) => stack === element.stack
    );
    if (!foundStackRoutes.length) {
      continue;
    }
    formattedRoutes.push([element.stack, foundStackRoutes]);
    temporaryRoutes = temporaryRoutes.filter(
      ({ stack }) => stack !== element.stack
    );
  }

  return formattedRoutes;
};
