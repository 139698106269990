import React from "react";
import { Box, colors, Stack, Typography } from "@mui/material";
import RoomActionButton from "../AbsenceReasonCardActionButton";

type Props = {
  title: string;
  color?: string;
  _id: string;
  refetch: any;
};

function AbsenceReasonCard({ title, _id, refetch, color }: Props) {
  return (
    <Box
      p={4}
      border={`1px solid ${colors.grey[300]}`}
      height="100%"
      display="grid"
      gap={1}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            width: 25,
            height: 25,
            background: color,
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        />
        <RoomActionButton absenceReason={_id} refetch={refetch} title={title} />
      </Stack>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
}

export default AbsenceReasonCard;
