import React, { MouseEvent, useCallback, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  colors,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { ISalary } from "interfaces/Salary";
import { usePermission } from "hooks/permission";
import t from "translate";

type Props = {
  salary: ISalary;
  handleDelete: VoidFunction;
  handleUpdate: VoidFunction;
};

function SalaryCard({ salary, handleDelete, handleUpdate }: Props) {
  const { amount, title, type, formula } = salary;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { PermissionComponents } = usePermission();

  const open = Boolean(anchorEl);

  const handleMoreClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const amountType = type === "fixed" ? "uzs" : type === "percent" ? "%" : "";

  return (
    <Card sx={{ height: "100%" }}>
      <Box
        display="flex"
        textAlign="center"
        flexDirection="column"
        component={CardContent}
        style={{ paddingBottom: 48 }}
      >
        <Box display="flex" justifyContent="flex-end">
          <PermissionComponents section="salaryTemplates" action="create">
            <IconButton size="small" onClick={handleMoreClick}>
              <MoreVertIcon />
            </IconButton>
          </PermissionComponents>
          <Menu
            open={open}
            id="basic-menu"
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem sx={{ gap: 1 }} onClick={handleUpdate}>
              <EditOutlinedIcon /> {t("edit")}
            </MenuItem>
            <MenuItem sx={{ gap: 1 }} onClick={handleDelete}>
              <DeleteOutlinedIcon />
              {t("delete")}
            </MenuItem>
          </Menu>
        </Box>
        <Box>
          <Typography variant="h5" sx={{ wordBreak: "break-word" }}>
            {formula || amount?.toLocaleString("Fi-fi")} {amountType}
          </Typography>
          <Typography variant="h6" color={colors.grey[600]}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default SalaryCard;
