import React from "react";
import { ReactComponent as EmptyDebtsIcon } from "./icons/emptyDebtors.svg";
import { Stack, Typography } from "@mui/material";
import t from "translate";

function EmptyDebtors() {
  return (
    <Stack alignItems="center">
      <EmptyDebtsIcon width={260} />
      <Typography variant="h5" mt={3}>
        {t("This teacher has no debtors!")}
      </Typography>
      <Typography variant="body1" color="GrayText">
        {t("This teachers' group doesn't have any debts!")}
      </Typography>
    </Stack>
  );
}

export default EmptyDebtors;
