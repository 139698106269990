import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  colors,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import t from "translate";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { useAppSelector } from "hooks/redux";
import { usePermission } from "hooks/permission";
import { gql, useMutation } from "@apollo/client";
import ConfirmModal from "components/ConfirmModal";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UpdateAbsenceReason from "../UpdateModal";
import DELETE_ABSENCE_REASON from "./api";

type Props = { refetch: any; absenceReason: string; title: string };

function AbsenceReasonActionButton({ refetch, absenceReason, title }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const { isActionAllowed } = usePermission();

  const [deleteRoom, { data, loading }] = useMutation(DELETE_ABSENCE_REASON);

  const handleMoreClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleDeleteModalVisible = useCallback(
    () => setIsDeleteModalVisible((prev) => !prev),
    []
  );
  const toggleUpdateModalVisible = useCallback(
    () => setIsUpdateModalVisible((prev) => !prev),
    []
  );

  const isDeleteAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "absenceReasons",
        action: "delete",
      }),
    [isActionAllowed]
  );
  const isEditAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "absenceReasons",
        action: "edit",
      }),
    [isActionAllowed]
  );

  const handleDelete = useCallback(() => {
    deleteRoom({
      variables: {
        absenceReason,
        center: centerId,
      },
    });
  }, [absenceReason, centerId, deleteRoom]);

  useEffect(() => {
    if (!loading && data) {
      refetch();
      toast.success(t("Absence reason deleted successfully!"));
      toggleDeleteModalVisible();
    }
  }, [data, loading]);

  if (!isDeleteAllowed && !isEditAllowed) {
    return <></>;
  }

  return (
    <>
      {loading && <Loader />}
      <IconButton size="small" onClick={handleMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "student-action-menu",
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {isEditAllowed && (
          <Box>
            <MenuItem sx={{ gap: 1 }} onClick={toggleUpdateModalVisible}>
              <EditOutlinedIcon color="warning" />
              <Typography color={colors.orange[800]}>{t("edit")}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}
        {isDeleteAllowed && (
          <MenuItem sx={{ gap: 1 }} onClick={toggleDeleteModalVisible}>
            <DeleteOutlinedIcon color="error" />
            <Typography color={colors.red[800]}>{t("delete")}</Typography>
          </MenuItem>
        )}
      </Menu>
      {isDeleteModalVisible && (
        <ConfirmModal
          onConfirm={handleDelete}
          open={isDeleteModalVisible}
          onClose={toggleDeleteModalVisible}
        />
      )}
      {isUpdateModalVisible && (
        <UpdateAbsenceReason
          title={title}
          absenceReason={absenceReason}
          refetch={refetch}
          open={isUpdateModalVisible}
          onClose={toggleUpdateModalVisible}
        />
      )}
    </>
  );
}

export default AbsenceReasonActionButton;
