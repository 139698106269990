import {
  AdjustmentAmountType,
  FinancialAdjustmentsType,
  Report,
} from "../../../types";

export const formatAdjustmentAmount = (
  adjustment: number,
  salaryAmount: number,
  adjustmentType: AdjustmentAmountType
) => {
  if (adjustmentType === "percentage" && adjustment > 100) {
    return salaryAmount;
  } else if (adjustmentType === "percentage") {
    return salaryAmount * (adjustment / 100);
  } else {
    return adjustment;
  }
};

export const calculateSalarySummaries = (
  reports: Report[],
  adjustments: FinancialAdjustmentsType
) => {
  return reports?.reduce(
    (
      acc,
      {
        _id,
        group,
        movedPaymentsFromLastMonth,
        movedPaymentsToNextMonth,
        paidAmountForPastMonths,
        payments,
        teacherSalaryAmount,
        teacherSalaryType,
        transfers,
      }
    ) => {
      let salary = 0;

      let totalPaid = payments?.total;

      totalPaid += paidAmountForPastMonths?.total || 0;
      totalPaid += movedPaymentsFromLastMonth?.total || 0;
      totalPaid -= movedPaymentsToNextMonth?.total || 0;
      totalPaid -= transfers?.[0]?.totalTransferred || 0;

      if (teacherSalaryType === "percent") {
        salary = totalPaid * (teacherSalaryAmount / 100);
      }

      const bonus = adjustments?.bonuses?.[_id || group || ""];
      const fine = adjustments?.fines?.[_id || group || ""];

      if (bonus) {
        salary += formatAdjustmentAmount(
          bonus?.amount || 0,
          totalPaid,
          bonus?.type
        );
      }

      if (fine) {
        salary -= formatAdjustmentAmount(fine.amount, totalPaid, fine.type);
      }

      return {
        totalPaid: acc.totalPaid + totalPaid,
        salaryAmount: acc.salaryAmount + salary,
        totalProfit: acc.totalProfit + (totalPaid - salary),
      };
    },
    { totalPaid: 0, salaryAmount: 0, totalProfit: 0 }
  );
};
