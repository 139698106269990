import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import Style from "./Style";
import t from "translate";
import {
  AppBar,
  Avatar,
  Box,
  colors,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { pxToRem } from "utils";
import { getImageUrl } from "helpers/image";
import { HEADER_ROUTES } from "./constants";
import HeaderDrawer from "./components/HeaderDrawer";
import HeaderDropdown from "./components/HeaderDropdown";
import StudentSearchInput from "./components/StudentSearchInput";
import { usePermission } from "hooks/permission";

const Header = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const user = useAppSelector((store) => store.auth.user);
  const navigate = useNavigate();
  const { isActionAllowed } = usePermission();

  const toggleDropdownVisible = useCallback(
    () => setIsDropdownVisible((prev) => !prev),
    []
  );
  const toggleDrawerVisible = useCallback(
    () => setIsDrawerVisible((prev) => !prev),
    []
  );

  const onDrawerLinkClick = useCallback(
    (path: string) => {
      navigate(path);
      toggleDrawerVisible();
    },
    [navigate, toggleDrawerVisible]
  );

  const isSearchAllowed = useMemo(() => {
    return isActionAllowed({ action: "viewInfo", section: "students" });
  }, [isActionAllowed]);

  return (
    <>
      <AppBar
        color="inherit"
        position="relative"
        sx={{
          zIndex: 999,
          maxWidth: "100vw",
          boxShadow: "none",
          borderBottom: "1px solid " + colors.grey[300],
        }}
        elevation={0}
      >
        <Style>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawerVisible}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Logo width={pxToRem(110)} />
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  ml: pxToRem(20),
                  gap: 2,
                }}
              >
                {HEADER_ROUTES.map(({ key, path, title }) => (
                  <Typography
                    key={key}
                    to={path}
                    component={Link}
                    textTransform="capitalize"
                  >
                    {t(title)}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              {isSearchAllowed && <StudentSearchInput />}
              <Tooltip title={t("Open settings")}>
                <IconButton onClick={toggleDropdownVisible} sx={{ p: 0 }}>
                  <Avatar
                    src={getImageUrl(user?.image)}
                    alt={user?.name + "image"}
                  >
                    {user?.name && user?.name[0]}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Style>
      </AppBar>
      {isDropdownVisible && (
        <HeaderDropdown
          onClose={toggleDropdownVisible}
          open={isDropdownVisible}
        />
      )}
      {isDrawerVisible && (
        <HeaderDrawer
          open={isDrawerVisible}
          onClose={toggleDrawerVisible}
          onLinkClick={onDrawerLinkClick}
        />
      )}
    </>
  );
};
export default React.memo(Header);
