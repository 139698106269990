import {
  Autocomplete,
  colors,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import t from "translate";

export type OptionType = {
  label: string;
  key: string;
  value: string;
  suffix?: string;
};

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  isRequired?: boolean;
  title: string;
  name?: string;
  options: OptionType[];
  onSelect?: (id: string) => void;
  errorMessage?: string;
  defaultValue?: string;
  margin?: "dense" | "normal" | "none" | undefined;
  suffix?: string;
  suffixColor?: string;
  loading?: boolean;
  fullWidth?: boolean;
};

function CustomMUISelect({
  control,
  errors,
  options,
  title,
  name,
  isRequired,
  onSelect = () => {},
  errorMessage,
  margin,
  suffix,
  loading = false,
  fullWidth = true,
  suffixColor,
}: Props) {
  return (
    <Controller
      name={name || title}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { value, onChange, ...field } }) => (
        <FormControl fullWidth={fullWidth} margin={margin}>
          <Autocomplete
            {...field}
            loading={loading}
            options={options}
            getOptionLabel={(option) => option.label}
            value={options.find((option) => option.value === value) || null}
            sx={{ width: fullWidth ? "auto" : 240 }}
            loadingText={t("Loading...")}
            onChange={(_, newValue) => {
              onSelect(newValue ? newValue.value : "");
              onChange(newValue ? newValue.value : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(title)}
                error={!!errors[field.name]}
                variant="outlined"
              />
            )}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                  key={option.key}
                >
                  <span>{option.label}</span>
                  <span
                    style={{
                      color: suffixColor || colors.grey[500],
                      fontSize: 14,
                    }}
                  >
                    {t(option.suffix || "")} {suffix}
                  </span>
                </li>
              );
            }}
          />
          {errors[field.name] && (
            <FormHelperText className="Mui-error">
              {t(errorMessage || "Please select!")}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default CustomMUISelect;
