import React, { useCallback, useEffect, useState } from "react";

import t from "translate";
import { Button, Grid, Pagination } from "@mui/material";
import PageHeader from "UI/PageHeader";

import { useQuery } from "@apollo/client";
import GET_PAGINATED_ABSENCE_REASONS from "./api";
import InnerLoader from "components/InnerLoader";
import NoData from "UI/NoData";
import AbsenceReasonCard from "./components/AbsenceReasonCard";
import { useAppSelector } from "hooks/redux";
import CreateAbsenceReasonModal from "./components/CreateModal";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";

type AbsenceReason = {
  _id: string;
  title: string;
};

function AbsenceReasons() {
  const [page, setPage] = useState(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const { data, loading, refetch } = useQuery(GET_PAGINATED_ABSENCE_REASONS, {
    variables: {
      center: centerId,
      page,
    },
  });

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen((prev) => !prev);
  }, []);

  const absenceReasons: AbsenceReason[] =
    data?.getPaginatedAbsenceReasons?.payload || [];
  const absenceReasonsTotalPages: number =
    data?.getPaginatedAbsenceReasons?.totalPages || 1;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <PageHeader title="Absence reasons" size="small">
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={toggleCreateModalOpen}
        >
          {t("create")}
        </Button>
      </PageHeader>
      {loading && <InnerLoader />}
      {!loading && !absenceReasons.length && <NoData />}
      <Grid container spacing={2}>
        {absenceReasons.map((reason) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AbsenceReasonCard
                key={reason._id}
                {...reason}
                refetch={refetch}
              />
            </Grid>
          );
        })}
      </Grid>
      <Pagination
        count={absenceReasonsTotalPages}
        page={page}
        onChange={(_, page) => setPage(page)}
      />
      {isCreateModalOpen && (
        <CreateAbsenceReasonModal
          open={isCreateModalOpen}
          onClose={toggleCreateModalOpen}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default AbsenceReasons;
