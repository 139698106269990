import { ISidebarRoute } from "interfaces/Route";
import paths from "appConstants/RoutePaths";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as AdmissionIcon } from "assets/icons/admission.svg";
import { ReactComponent as CourseIcon } from "assets/icons/courses.svg";
import { ReactComponent as GroupsIcon } from "assets/icons/groups.svg";
import { ReactComponent as StudentsIcon } from "assets/icons/students.svg";
import { ReactComponent as EmployeeIcon } from "assets/icons/employees.svg";
import { ReactComponent as PaymentsIcon } from "assets/icons/payments.svg";
import { ReactComponent as ScheduleIcon } from "assets/icons/schedule.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as SessionsIcon } from "assets/icons/sessions.svg";
import { ReactComponent as SMSIcon } from "assets/icons/sms.svg";
import { ReactComponent as ExpensesIcon } from "assets/icons/expenses.svg";
import { ReactComponent as LeaveAndAbsenceReasonsIcon } from "assets/icons/absencesAndLeaveReasons.svg";
import { ReactComponent as SalaryPaymentsIcon } from "assets/icons/salaryPayments.svg";
import { ReactComponent as SalaryTemplatesIcon } from "assets/icons/salariesTemplates.svg";
import { ReactComponent as DebtorsIcon } from "assets/icons/debtor.svg";
import { ReactComponent as FrozenStudentsIcon } from "assets/icons/frozen-students.svg";
import { ReactComponent as StudentAbsencesIcon } from "assets/icons/studentAbsences.svg";
import { ReactComponent as GroupAbsencesIcon } from "assets/icons/groupAbsences.svg";
import { ReactComponent as RoomsIcon } from "assets/icons/rooms.svg";
import { ReactComponent as RoadmapsIcon } from "assets/icons/roadmaps.svg";
import { ReactComponent as DiscountsIcon } from "assets/icons/discounts.svg";

const {
  CENTER_INFO,
  CENTER_DASHBOARD,
  CENTER_ADMISSION,
  CENTER_COURSES,
  CENTER_GROUPS,
  CENTER_STUDENTS,
  CENTER_EMPLOYEES,
  CENTER_PAYMENTS,
  CENTER_SETTING,
  CENTER_SCHEDULE,
  CENTER_EXPENSES,
  CENTER_STUDENT_ABSENCES,
  CENTER_SMS,
  CENTER_SESSIONS,
  CENTER_SALARY_PAYMENTS,
  CENTER_SALARY_DEBTORS,
  CENTER_FROZEN_STUDENTS,
  CENTER_GROUP_ABSENCES,
  CENTER_SALARY_TEMPLATES,
  CENTER_LEAVE_ABSENCE_TEMPLATES,
  CENTER_ROOMS,
  CENTER_DISCOUNTS,
  CENTER_ROADMAPS,
} = paths;

const SIDEBAR_ROUTES: ISidebarRoute[] = [
  {
    title: "home",
    path: CENTER_INFO,
    key: "info",
    section: "info",
    action: "view",
    type: "link",
    icon: <HomeIcon className="sidebar__link-icon" />,
    stack: "general",
  },
  {
    title: "dashboard",
    path: CENTER_DASHBOARD,
    key: "dashboard",
    section: "dashboard",
    action: "view",
    type: "link",
    icon: <DashboardIcon className="sidebar__link-icon" />,
    stack: "general",
  },
  {
    title: "admission",
    path: CENTER_ADMISSION,
    key: "admission",
    section: "admission",
    action: "view",
    type: "link",
    icon: <AdmissionIcon className="sidebar__link-icon" />,
    stack: "general",
  },
  {
    title: "courses",
    path: CENTER_COURSES,
    key: "courses",
    section: "courses",
    action: "view",
    type: "link",
    icon: <CourseIcon className="sidebar__link-icon" />,
    stack: "general",
  },
  {
    title: "groups",
    path: CENTER_GROUPS,
    key: "groups",
    section: "groups",
    action: "view",
    type: "link",
    icon: <GroupsIcon className="sidebar__link-icon" />,
    stack: "general",
  },
  {
    title: "students",
    path: CENTER_STUDENTS,
    key: "students",
    section: "students",
    action: "view",
    type: "link",
    icon: <StudentsIcon className="sidebar__link-icon" />,
    stack: "general",
  },

  {
    title: "payments",
    path: CENTER_PAYMENTS,
    key: "payments",
    section: "payments",
    action: "view",
    type: "link",
    icon: <PaymentsIcon className="sidebar__link-icon" />,
    stack: "finance",
  },
  {
    title: "debtors",
    path: CENTER_SALARY_DEBTORS,
    key: "debtors",
    section: "debtors",
    action: "view",
    type: "link",
    icon: <DebtorsIcon className="sidebar__link-icon" />,
    stack: "finance",
  },
  {
    title: "expenses",
    path: CENTER_EXPENSES,
    key: "expenses",
    section: "expenses",
    action: "view",
    type: "link",
    icon: <ExpensesIcon className="sidebar__link-icon" />,
    stack: "finance",
  },

  {
    title: "schedule",
    path: CENTER_SCHEDULE,
    key: "schedule",
    section: "schedule",
    action: "view",
    type: "link",
    icon: <ScheduleIcon className="sidebar__link-icon" />,
    stack: "calendar",
  },
  {
    title: "Student absences",
    path: CENTER_STUDENT_ABSENCES,
    key: "absences",
    section: "studentAbsences",
    extraSection: "groupAbsences",
    action: "view",
    type: "link",
    icon: <StudentAbsencesIcon className="sidebar__link-icon" />,
    stack: "calendar",
  },
  {
    title: "Group absences",
    path: CENTER_GROUP_ABSENCES,
    key: "groupAbsences",
    section: "studentAbsences",
    action: "view",
    icon: <GroupAbsencesIcon />,
    stack: "calendar",
    type: "link",
  },
  {
    title: "Frozen students",
    path: CENTER_FROZEN_STUDENTS,
    key: "frozenStudents",
    section: "studentAbsences",
    action: "view",
    type: "link",
    icon: <FrozenStudentsIcon className="sidebar__link-icon" />,
    stack: "calendar",
  },
  {
    title: "lessons",
    path: CENTER_SESSIONS,
    key: "sessionsPage",
    section: "sessions",
    action: "view",
    type: "link",
    icon: <SessionsIcon className="sidebar__link-icon" />,
    stack: "calendar",
  },
  {
    title: "employees",
    path: CENTER_EMPLOYEES,
    key: "employees",
    section: "employees",
    action: "view",
    type: "link",
    icon: <EmployeeIcon className="sidebar__link-icon" />,
    stack: "employees",
  },
  {
    title: "salaries",
    path: CENTER_SALARY_PAYMENTS,
    key: "salaryPayments",
    section: "salaryPayments",
    action: "view",
    type: "link",
    icon: <SalaryPaymentsIcon className="sidebar__link-icon" />,
    stack: "employees",
  },

  {
    title: "salaries",
    path: CENTER_SALARY_TEMPLATES,
    key: "salaryTemplates",
    section: "salaryTemplates",
    action: "view",
    type: "link",
    icon: <SalaryTemplatesIcon className="sidebar__link-icon" />,
    stack: "templates",
  },
  {
    title: "Absence reasons",
    path: CENTER_LEAVE_ABSENCE_TEMPLATES,
    key: "salaryPayments",
    section: "salaryPayments",
    action: "view",
    type: "link",
    icon: <LeaveAndAbsenceReasonsIcon className="sidebar__link-icon" />,
    stack: "templates",
  },
  {
    title: "rooms",
    path: CENTER_ROOMS,
    key: "rooms",
    section: "rooms",
    action: "view",
    type: "link",
    icon: <RoomsIcon className="sidebar__link-icon" />,
    stack: "templates",
  },
  {
    title: "Roadmaps",
    path: CENTER_ROADMAPS,
    key: "roadmaps",
    section: "roadmaps",
    action: "view",
    type: "link",
    icon: <RoadmapsIcon className="sidebar__link-icon" />,
    stack: "templates",
  },
  {
    title: "discounts",
    path: CENTER_DISCOUNTS,
    key: "discounts",
    section: "discounts",
    action: "view",
    type: "link",
    icon: <DiscountsIcon className="sidebar__link-icon" />,
    stack: "templates",
  },
  {
    title: "settings",
    path: CENTER_SETTING,
    key: "settings",
    section: "settings",
    action: "view",
    type: "link",
    icon: <SettingsIcon className="sidebar__link-icon" />,
    stack: "settings",
  },
  {
    title: "sms",
    path: CENTER_SMS,
    key: "sms",
    section: "sms",
    action: "view",
    type: "link",
    icon: <SMSIcon className="sidebar__link-icon" />,
    stack: "Extras",
  },
];

export default SIDEBAR_ROUTES;
