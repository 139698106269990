import { gql } from "@apollo/client";

const GET_PAGINATED_ABSENCE_REASONS = gql`
  query getPaginatedAbsenceReasons($center: ID!, $page: Int, $size: Int) {
    getPaginatedAbsenceReasons(center: $center, page: $page, size: $size) {
      payload {
        _id
        title
        color
      }
      totalPages
    }
  }
`;

export default GET_PAGINATED_ABSENCE_REASONS;
