import React from "react";
import {
  AdjustmentType,
  FinancialAdjustmentsType,
  SalaryReportType,
} from "../../../../types";
import { calculateSalarySummaries } from "../../helpers";
import {
  colors,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import t from "translate";
import SalaryReportRow from "../SalaryReportRow";
import { formatAmount } from "helpers/amountHandlers";

type Props = {
  reports: SalaryReportType;
  adjustments: FinancialAdjustmentsType;
  advanceAmount?: number;
  onAddAdjustment: (group: string, type: AdjustmentType) => void;
  onDeleteAdjustment: (group: string, type: AdjustmentType) => void;
  readonly?: boolean;
};

function TeacherSalaryReport({
  reports,
  adjustments,
  advanceAmount,
  onAddAdjustment,
  onDeleteAdjustment,
  readonly,
}: Props) {
  const salarySummary = calculateSalarySummaries(
    reports?.reports || [],
    adjustments
  );

  const salaryAmount =
    salarySummary?.salaryAmount - (advanceAmount || reports?.totalAdvance || 0);

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ maxWidth: 50 }}></TableCell>
            <TableCell component="th">{t("group")}</TableCell>
            <TableCell component="th">{t("Total paid")}</TableCell>
            <TableCell component="th">{t("salary amount")}</TableCell>
            <TableCell component="th">{t("Profit")}</TableCell>
            {!readonly && <TableCell component="th">{t("actions")}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports?.reports?.map((report) => (
            <SalaryReportRow
              key={report._id}
              report={report}
              adjustments={adjustments}
              onDeleteAdjustment={onDeleteAdjustment}
              onAddAdjustment={onAddAdjustment}
              readonly={readonly}
            />
          ))}
          <TableRow sx={{ bgcolor: colors.blue[50] }}>
            <TableCell sx={{ maxWidth: 50 }}></TableCell>
            <TableCell>{t("advance")}</TableCell>
            <TableCell></TableCell>
            <TableCell>
              {(advanceAmount || reports?.totalAdvance || 0)?.toLocaleString(
                "fi-FI"
              )}{" "}
              uzs
            </TableCell>
            <TableCell></TableCell>
            {!readonly && <TableCell></TableCell>}
          </TableRow>
        </TableBody>
        <TableRow>
          <TableCell sx={{ maxWidth: 50 }}></TableCell>
          <TableCell>{t("Total")}</TableCell>
          <TableCell>
            {formatAmount(salarySummary.totalPaid, 2)}
            uzs
          </TableCell>
          <TableCell>
            {formatAmount(salaryAmount, 2)}
            uzs
          </TableCell>
          <TableCell>
            {formatAmount(salarySummary.totalProfit, 2)}
            uzs
          </TableCell>
          {!readonly && <TableCell></TableCell>}
        </TableRow>
      </Table>
    </TableContainer>
  );
}

export default TeacherSalaryReport;
