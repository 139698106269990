import React, { useCallback, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import t from "translate";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useAppSelector } from "hooks/redux";
import { Controller, FieldValues, useForm } from "react-hook-form";

import Loader from "components/Loader";
import { RefetchType } from "interfaces";
import { CREATE_ABSENCE_REASON } from "./api";
import ColorPicker from "components/ColorPicker";

import CloseIcon from "@mui/icons-material/Close";

type Props = {
  open: boolean;
  refetch: RefetchType;
  onClose: VoidFunction;
};

function CreateAbsenceReasonModal({ refetch, onClose, open }: Props) {
  const [createAbsenceReason, { data, loading }] = useMutation(
    CREATE_ABSENCE_REASON
  );

  const centerId = useAppSelector((state) => state.center.center?._id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = useCallback(
    ({ title, color }: FieldValues) => {
      createAbsenceReason({
        variables: {
          title,
          center: centerId,
          color,
        },
      });
    },
    [centerId, createAbsenceReason]
  );

  const handleClose = useCallback(() => {
    reset({});
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Absence reason created successfully!"));
      refetch();
      handleClose();
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <DialogTitle>{t("Create absence reason")}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <Controller
            name="title"
            rules={{
              required: true,
              maxLength: 25,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    required
                    {...field}
                    value={field.value || ""}
                    label={t("Absence reason title")}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] &&
                      t("Please input the absence reason title!")
                    }
                  />
                </FormControl>
              );
            }}
          />
          <ColorPicker control={control} />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={handleClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateAbsenceReasonModal;
