import { gql } from "@apollo/client";

const DELETE_ABSENCE_REASON = gql`
  mutation deleteAbsenceReason($absenceReason: ID!, $center: ID!) {
    deleteAbsenceReason(absenceReason: $absenceReason, center: $center) {
      payload {
        _id
      }
    }
  }
`;

export default DELETE_ABSENCE_REASON;
