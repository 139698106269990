import { gql } from "@apollo/client";

export const GET_EMPLOYEES = gql`
  query getEmployees($centerId: ID!) {
    getEmployees(centerId: $centerId) {
      payload {
        _id
        name
        phone
        image
      }
    }
  }
`;
