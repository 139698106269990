import styled from "styled-components";
import { pxToRem } from "utils";

export default styled.div`
  width: 100%;
  max-width: ${pxToRem(1440)};
  margin: 0 auto;
  padding: ${pxToRem(40)};

  @media screen and (max-width: 700px) {
    padding: ${pxToRem(40)} ${pxToRem(20)};
  }

  @media print {
    max-width: 100%;
  }
`;
