type SelectDataType = {
  [key: string]: any;
};

type ArgsType = {
  data: SelectDataType[];
  labelKey: string;
  valueKey: string;
  extraLabelKey?: string;
  extraValueKey?: string;
  suffixKey?: string;
  suffixFormatter?: (suffix: unknown) => string;
};

export const formatDataForSelect = ({
  data,
  labelKey,
  valueKey,
  extraValueKey = "",
  extraLabelKey = "",
  suffixKey = "",
  suffixFormatter,
}: ArgsType) => {
  const splittedLabelKey = labelKey.split(".");
  const splittedValueKey = valueKey.split(".");
  if (data.length) {
    return data.map((item) => {
      const { option } = splittedLabelKey.reduce(
        (acc, currentKey, i) => {
          const label =
            acc.temporaryOption[currentKey] ||
            acc.temporaryOption[extraLabelKey];
          const value =
            acc.temporaryOption[splittedValueKey[i]] ||
            acc.temporaryOption[extraValueKey];
          let suffix = acc.temporaryOption[suffixKey];

          if (suffixFormatter) {
            suffix = suffixFormatter(suffix);
          }

          if (typeof label === "object") {
            acc.temporaryOption = {
              ...label,
              [suffixKey]: !label[suffixKey] ? suffix : label[suffixKey],
            };
          }
          acc.option = {
            label,
            value: value || acc.option.value,
            key: value || acc.option.value,
            suffix: suffix || "",
          };

          return acc;
        },
        {
          option: {
            label: splittedLabelKey.slice(-1)[0],
            value: splittedValueKey.slice(-1)[0],
            key: splittedValueKey.slice(-1)[0],
            suffix: "",
          },
          temporaryOption: item,
        }
      );
      return option;
    });
  }
  return [];
};
