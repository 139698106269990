import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { colors, createTheme } from "@mui/material";
import styleColors from "styles/colors";
import { pxToRem } from "utils";

export const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "large" },
          style: {
            paddingTop: pxToRem(14),
            paddingBottom: pxToRem(14),
          },
        },
        {
          props: { size: "medium" },
          style: {
            paddingTop: pxToRem(10),
            paddingBottom: pxToRem(10),
          },
        },
        {
          props: { size: "small" },
          style: {
            paddingTop: pxToRem(6),
            paddingBottom: pxToRem(6),
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        outlinedWarning: {
          color: colors.amber[700],
          borderColor: colors.amber[400],
          ":hover": {
            backgroundColor: colors.amber[50] + "40",
            borderColor: colors.amber[600],
          },
        },
        containedWarning: {
          borderColor: colors.amber[600],
          backgroundColor: colors.amber[600],
          ":hover": {
            borderColor: colors.amber[700],
            backgroundColor: colors.amber[700],
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: `0 ${pxToRem(2)} ${pxToRem(10)} 0 ${
            styleColors.boxShadow
          }`,
          border: `${pxToRem(0.5)} solid ${styleColors.boxShadow}`,
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "elevation", elevation: 1 },
          style: {
            boxShadow: `0 ${pxToRem(2)} ${pxToRem(10)} 0 ${
              styleColors.boxShadow
            }`,
            border: `${pxToRem(0.5)} solid ${styleColors.boxShadow}`,
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          gap: pxToRem(20),
          width: pxToRem(520),
          paddingTop: pxToRem(20),
          paddingBottom: pxToRem(20),
          paddingLeft: pxToRem(24),
          paddingRight: pxToRem(24),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          columnGap: pxToRem(6),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: `${pxToRem(8)} !important`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: {
          paddingTop: `${pxToRem(10)} !important`,
          paddingBottom: `${pxToRem(10)} !important`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px -2px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          textTransform: "capitalize",
        },
        colorWarning: {
          color: colors.amber[700],
          background: colors.amber[100],
        },
        colorSuccess: {
          color: colors.green[700],
          background: colors.green[100],
        },
        colorError: {
          color: colors.red[700],
          background: colors.red[100],
        },
        colorInfo: {
          color: colors.blue[700],
          background: colors.blue[100],
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: pxToRem(20),
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          width: pxToRem(40),
          height: pxToRem(40),
          // boxShadow: "none",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeadersInner: {
          background: colors.grey[100],
        },
        columnHeader: {
          ":focus": {
            outline: 0,
          },
          ":focus-visible": {
            outline: 0,
          },
          ":focus-within": {
            outline: 0,
          },
        },
        cell: {
          ":focus": {
            outline: 0,
          },
          ":focus-visible": {
            outline: 0,
          },
          ":focus-within": {
            outline: 0,
          },
          whiteSpace: "break-spaces !important",
        },
        columnHeaderTitle: {
          fontWeight: 600,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: pxToRem(13),
        },
      },
    },
  },
});
