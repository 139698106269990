export const DebtorStyle = `
  <style>
  body {
    padding: 6px;
    margin: 0;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    margin: 20px 0 0 0;
  }

  td,
  th,
  table {
    border: 1px solid rgba(224, 224, 224, 1);
    border-collapse: collapse;
  }

  td,
  th {
    padding: 10px;
  }

  td {
    font-size: 14px;
  }

  .empty-debt-month {
    text-align: center;
  }

  .debt-info__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .debt-info__wrapper:first-child {
    margin-bottom: 6px;
  }

  .student-phone {
    font-size: 12px;
    margin-top: 6px;
  }

  .student-name {
    font-size: 14px;
  }
              
  </style>
`;
