import { GridColDef } from "@mui/x-data-grid";
import { Avatar, Box, Button, Typography } from "@mui/material";
import t from "translate";
import { getImageUrl } from "helpers/image";
import moment from "moment";

export const getGroupTableColumns = (onClick: (id: string) => void) => {
  const GROUPS_TABLE_COLUMNS: GridColDef[] = [
    {
      flex: 1,
      minWidth: 150,
      field: "title",
      headerName: t("Group Name"),
    },
    {
      flex: 2,
      minWidth: 200,
      field: "teacher",
      headerName: t("teacher"),
      renderCell: ({ row }) => {
        const teacher = row?.teacher || {};
        return (
          <Box display="flex" gap={1} alignItems="center">
            <Avatar src={getImageUrl(teacher?.image)} alt="teacher">
              {teacher?.name && teacher?.name[0]}
            </Avatar>
            <Typography variant="body2" whiteSpace="break-spaces">
              {teacher?.name || t("not selected")}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: "course",
      headerName: t("Course"),
      renderCell: ({ row }) => {
        return row?.course?.title || "N/A";
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: "subject",
      headerName: t("Subject"),
      renderCell: ({ row }) => {
        return row?.currentSubject?.title || "N/A";
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: "room",
      headerName: t("Room"),
      renderCell: ({ row }) => {
        return row?.room?.title || "N/A";
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "price",
      headerName: t("price"),
      renderCell: ({ row }) => {
        return row?.price?.toLocaleString("Fi-fi") + " uzs";
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "days",
      headerName: t("days"),
      renderCell: ({ row }) => {
        return row?.days?.map((day: string) => t(day)).join(", ");
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "hours",
      headerName: t("hours"),
      renderCell: ({ row }) => {
        return (
          moment(row?.startTime).format("HH:mm") +
          "-" +
          moment(row?.endTime).format("HH:mm")
        );
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "_id",
      headerName: t("action"),
      renderCell: ({ row }) => {
        return <Button onClick={() => onClick(row._id)}>{t("view")}</Button>;
      },
    },
  ];
  return GROUPS_TABLE_COLUMNS;
};
