import { Button, Grid, Pagination } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import t from "translate";
import PageHeader from "UI/PageHeader";
import CreateRoadmap from "./components/Create";
import RoadmapCard from "./components/Card";
import { useQuery } from "@apollo/client";
import { GET_ROADMAPS } from "./api";
import { useAppSelector } from "hooks/redux";
import InnerLoader from "components/InnerLoader";
import NoData from "UI/NoData";
import { RoadmapType } from "./types";
import paths from "appConstants/RoutePaths";
import { usePermission } from "hooks/permission";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";

function Roadmap() {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const { PermissionComponents } = usePermission();

  const { data, loading, refetch } = useQuery(GET_ROADMAPS, {
    variables: {
      center: centerId,
      page: currentPage,
    },
  });

  const roadmaps: RoadmapType[] = data?.getPaginatedRoadmaps?.payload || [];
  const totalPages = data?.getPaginatedRoadmaps?.totalPages || 1;

  const toggleCreateModalVisible = useCallback(
    () => setIsCreateModalVisible((prev) => !prev),
    []
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <PageHeader title="Roadmaps">
        <PermissionComponents section="roadmaps" action="create">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={toggleCreateModalVisible}
          >
            {t("Create new")}
          </Button>
        </PermissionComponents>
      </PageHeader>
      {loading ? (
        <InnerLoader />
      ) : !roadmaps.length ? (
        <NoData />
      ) : (
        <>
          <Grid container spacing={2}>
            {roadmaps.map((roadmap) => (
              <Grid item xs={12} md={4} lg={3} key={roadmap._id}>
                <RoadmapCard
                  {...roadmap}
                  numberOfUnits={roadmap.subjectsAmount}
                  path={paths.CENTER_ROADMAP_INFO}
                  section="subjects"
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Pagination
        count={totalPages}
        onChange={(_, page) => setCurrentPage(page)}
        page={currentPage}
      />
      {isCreateModalVisible && (
        <CreateRoadmap
          refetch={refetch}
          onClose={toggleCreateModalVisible}
          open={isCreateModalVisible}
        />
      )}
    </>
  );
}

export default Roadmap;
