import { gql } from "@apollo/client";

export const GET_SALARY_PAYMENT_INFO = gql`
  query getSalaryPaymentInfo($center: ID!, $salaryPaymentId: ID!) {
    getSalaryPaymentInfo(center: $center, salaryPaymentId: $salaryPaymentId) {
      payload {
        _id
        employee {
          name
        }
        createdBy {
          name
        }
        totalAdvanceAmount
        month
        reports {
          group
          title
          teacherSalaryAmount
          teacherSalaryType
          payments {
            total
          }
          expectedAmount {
            total
          }
          paidAmountForPastMonths {
            total
          }
          movedPaymentsToNextMonth {
            total
          }
          movedPaymentsFromLastMonth {
            total
          }
          transfers {
            totalTransferred
          }
        }
        adjustments
        amount
        status
        createdAt
        salaryType
      }
    }
  }
`;
