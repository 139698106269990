import React, { useEffect } from "react";
import { Control, FieldValues, FieldErrors } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_EMPLOYEES } from "./api";
import { useAppSelector } from "hooks/redux";
import Loader from "components/Loader";
import CustomMUISelect from "components/CustomMuiSelect";
import { formatDataForSelect } from "helpers/selectFormater";

type Props = {
  control: Control<FieldValues, any>;
  defaultValue?: string;
  errors: FieldErrors<FieldValues>;
  title?: string;
  onSelect?: (id: string) => void;
  name?: string;
  required?: boolean;
  helperText?: string;
  isFullWith?: boolean;
  margin?: "dense" | "normal" | "none";
};

type EmployeeType = {
  _id: string;
  name: string;
  phone: string;
  image: string;
};

function TeachersSelect({
  control,
  errors,
  title,
  name,
  defaultValue,
  required = false,
  onSelect = () => {},
  helperText,
  isFullWith = true,
  margin,
}: Props) {
  const centerId = useAppSelector((state) => state.center.center?._id);
  const { data, loading, refetch } = useQuery(GET_EMPLOYEES, {
    variables: { centerId },
  });

  const employees: EmployeeType[] = data?.getEmployees?.payload || [];

  useEffect(() => {
    refetch();
  }, []);

  const options = formatDataForSelect({
    data: employees,
    labelKey: "name",
    valueKey: "_id",
  });

  return (
    <>
      {loading ? <Loader /> : null}

      <CustomMUISelect
        title={title || "teacher"}
        control={control}
        errors={errors}
        options={options}
        name={name || "teacher"}
        isRequired={required}
        defaultValue={defaultValue}
        onSelect={onSelect}
        fullWidth={isFullWith}
        errorMessage={helperText}
        margin={margin}
      />
    </>
  );
}

export default TeachersSelect;
