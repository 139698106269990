import { pxToRem } from "utils";
import styled from "styled-components";
import { HEADER_HEIGHT, HEADER_RESPONSIVE_HEIGHT } from "./constants/styles";
import { colors } from "@mui/material";

export const StyledSidebar = styled.div`
  .sidebar {
    min-width: ${pxToRem(300)};
    max-width: ${pxToRem(300)};
    height: calc(100vh - ${pxToRem(HEADER_HEIGHT)});
    position: sticky;
    top: 0;
    overflow-y: auto;
    border-right: 1px solid ${colors.grey[300]};
    padding: 20px 0;
  }

  .sidebar__link-stack-wrapper {
    padding: 0 ${pxToRem(20)};
  }

  .sidebar__link--setting {
    margin-top: auto;
  }

  .sidebar__link {
    padding: ${pxToRem(10)} ${pxToRem(20)};
    border-radius: ${pxToRem(8)};
    display: block;
    border: 1px solid transparent;
    color: ${colors.grey[800]};
  }

  .sidebar__link:hover {
    background: #e9f2ff;
  }

  .sidebar__link--active,
  .sidebar__link--active:hover {
    background: #e9f2ff;
    color: #0d67e4;
    border-color: #0d67e4;
  }

  .sidebar__link-icon {
    min-width: 24px;
    min-height: 24px;
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }

  .sidebar__link--wrapper {
    min-height: ${pxToRem(25)};
  }

  .sidebar__child-side {
    width: 100%;
    height: calc(100vh - ${pxToRem(HEADER_HEIGHT)});
    overflow-y: auto;
  }

  .sidebar__stack-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    color: ${colors.grey[500]};
    margin: 14px 0;
  }

  @media print {
    .sidebar {
      display: none;
    }
    .sidebar__child-side {
      height: auto;
    }
  }

  @media (max-width: 900px) {
    .sidebar {
      min-width: ${pxToRem(74)};
      max-width: ${pxToRem(74)};
      padding: ${pxToRem(40)} ${pxToRem(10)};
      transform: translateX(0);
    }
    .sidebar,
    .sidebar__child-side {
      height: calc(100vh - ${pxToRem(HEADER_RESPONSIVE_HEIGHT)});
    }
    .sidebar__link--wrapper {
      flex-direction: column !important;
      height: auto;
      font-size: ${pxToRem(12)};
    }

    .sidebar__link {
      text-align: center;
      padding: ${pxToRem(8)} 0;
    }

    .sidebar__link-inner {
      gap: 0;
      justify-content: center;
    }

    .sidebar__link-text {
      display: none;
    }

    .sidebar__link-icon {
      width: ${pxToRem(26)};
      height: ${pxToRem(26)};
    }

    .icon {
      margin-right: 0;
    }
    .sidebar__link-stack-wrapper {
      padding: 0;
    }
    .sidebar__stack-title {
      display: none;
    }
  }
`;

export const StyledSidebarNav = styled.div``;
