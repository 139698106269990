import { useQuery } from "@apollo/client";
import React from "react";
import { GET_SALARY_PAYMENT_INFO } from "./api";
import InnerLoader from "components/InnerLoader";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import t from "translate";
import TeacherSalaryReport from "../CreateSalaryPayment/components/TeacherSalaryReport";

function SalaryPaymentInfo() {
  const centerId = useAppSelector((store) => store.center.center?._id);
  const salaryPaymentId = useParams();

  const { data, loading } = useQuery(GET_SALARY_PAYMENT_INFO, {
    variables: {
      center: centerId,
      salaryPaymentId: salaryPaymentId.id,
    },
    fetchPolicy: "no-cache",
  });

  const reports = data?.getSalaryPaymentInfo?.payload || {};
  if (loading) {
    return <InnerLoader />;
  }

  return (
    <>
      <Stack gap={2}>
        <Typography variant="h5">{reports?.employee?.name}</Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="subtitle1" color="GrayText">
            {t("Month")}:
          </Typography>
          <Typography variant="body1" fontWeight="600">
            {moment(reports?.month).format("MMMM")}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="subtitle1" color="GrayText">
            {t("created at")}:
          </Typography>
          <Typography variant="body1" fontWeight="600">
            {moment(reports?.createdAt).format("DD.MM.YYYY")}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="subtitle1" color="GrayText">
            {t("created by")}:
          </Typography>
          <Typography variant="body1" fontWeight="600">
            {reports?.createdBy?.name}
          </Typography>
        </Stack>
        <TeacherSalaryReport
          reports={reports}
          adjustments={reports?.adjustments || {}}
          advanceAmount={reports.totalAdvanceAmount}
          onAddAdjustment={() => {}}
          onDeleteAdjustment={() => {}}
          readonly
        />
      </Stack>
    </>
  );
}

export default SalaryPaymentInfo;
