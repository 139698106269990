import { useQuery } from "@apollo/client";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import PageHeader from "UI/PageHeader";
import GET_GROUPS_ABSENCE from "./api";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import InnerLoader from "components/InnerLoader";
import { DataGrid } from "@mui/x-data-grid";
import { IGroup } from "interfaces/Groups";
import { getGroupTableColumns } from "pages/Center/Groups/components/GroupsTable/constants";
import { useNavigate } from "react-router-dom";
import { addGroupID } from "store/reducer/CenterSlice";
import paths from "appConstants/RoutePaths";
import NoData from "UI/NoData";
import GroupAbsenceStyle from "./GroupAbsence.style";

function GroupsAbsence() {
  const [selectedDate, setSelectedDate] = useState<unknown>(moment());

  const centerId = useAppSelector((state) => state.center.center?._id);

  const { data, loading } = useQuery(GET_GROUPS_ABSENCE, {
    variables: {
      date: selectedDate,
      center: centerId,
    },
  });

  const groups: IGroup[] = data?.getMissedLessonGroups?.payload || [];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateToInfo = useCallback(
    (id: string) => {
      dispatch(addGroupID(id));
      navigate(paths.CENTER_GROUPS_INFO);
    },
    [dispatch, navigate]
  );

  const columns = useMemo(
    () => getGroupTableColumns(handleNavigateToInfo),
    [handleNavigateToInfo]
  );

  const getRowClassName = useCallback(() => {
    return "miss-lesson-group";
  }, []);

  return (
    <GroupAbsenceStyle>
      <PageHeader title="group absences" size="small">
        <DatePicker
          format="DD/MM/YYYY"
          onChange={(date) => setSelectedDate(date)}
          disableFuture
        />
      </PageHeader>
      {loading ? (
        <InnerLoader />
      ) : (
        <div style={{ height: !groups.length ? 400 : "100%" }}>
          <DataGrid
            hideFooter
            rows={groups}
            disableColumnMenu
            columns={columns}
            slots={{ noRowsOverlay: NoData, noResultsOverlay: NoData }}
            getRowId={({ _id }) => _id}
            getRowClassName={getRowClassName}
            showCellVerticalBorder
            showColumnVerticalBorder
          />
        </div>
      )}
    </GroupAbsenceStyle>
  );
}

export default GroupsAbsence;
