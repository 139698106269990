import React from "react";
import {
  AdjustmentType,
  FinancialAdjustmentsType,
  Report,
} from "../../../../types";
import {
  Box,
  Chip,
  Collapse,
  colors,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { ReactComponent as GiftIcon } from "../../icons/gift.svg";
import { ReactComponent as GavelIcon } from "../../icons/gavel.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatAdjustmentAmount } from "../../helpers";
import t from "translate";
import { formatAmount } from "helpers/amountHandlers";

type Props = {
  report: Report;
  adjustments: FinancialAdjustmentsType;
  onDeleteAdjustment: (group: string, type: AdjustmentType) => void;
  onAddAdjustment: (group: string, type: AdjustmentType) => void;
  readonly?: boolean;
};

function SalaryReportRow({
  report,
  adjustments,
  onDeleteAdjustment,
  onAddAdjustment,
  readonly,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const {
    _id,
    payments,
    teacherSalaryAmount,
    teacherSalaryType,
    movedPaymentsFromLastMonth,
    movedPaymentsToNextMonth,
    expectedAmount,
    paidAmountForPastMonths,
    title,
    transfers,
    group,
  } = report;

  let salaryAmount = 0;

  let totalPaid = payments.total;
  totalPaid += movedPaymentsFromLastMonth?.total || 0;
  totalPaid += paidAmountForPastMonths?.total || 0;
  totalPaid -= movedPaymentsToNextMonth?.total || 0;
  totalPaid -= transfers?.[0]?.totalTransferred || 0;

  if (teacherSalaryType === "percent") {
    const salaryPercentage = teacherSalaryAmount || 0;
    salaryAmount = totalPaid * (salaryPercentage / 100);
  } else {
    salaryAmount = totalPaid - teacherSalaryAmount || 0;
  }

  const bonusAmount = adjustments?.bonuses?.[_id || group || ""]?.amount || 0;
  const bonusType =
    adjustments?.bonuses?.[_id || group || ""]?.type || "percentage";
  const bonus = formatAdjustmentAmount(bonusAmount, totalPaid, bonusType);

  const fineAmount = adjustments?.fines?.[_id || group || ""]?.amount || 0;
  const fineType =
    adjustments?.fines?.[_id || group || ""]?.type || "percentage";

  const fine = formatAdjustmentAmount(fineAmount, totalPaid, fineType);

  const profit = totalPaid - (salaryAmount + bonus - fine);

  return (
    <React.Fragment key={_id}>
      <TableRow>
        <TableCell sx={{ maxWidth: 50 }} align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th">
          {title}{" "}
          <Typography variant="body2" color={colors.blue[600]}>
            {teacherSalaryAmount}{" "}
            {teacherSalaryType === "percent" ? "%" : "uzs"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {formatAmount(totalPaid, 2)} uzs
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {formatAmount(salaryAmount, 2)} uzs
          </Typography>
          {!!bonus && (
            <Chip
              label={"+" + formatAmount(bonus)}
              size="small"
              color="success"
              variant="outlined"
              onDelete={() => onDeleteAdjustment(_id || group || "", "bonuses")}
              sx={{ mr: 1 }}
            />
          )}
          {!!fine && (
            <Chip
              label={"-" + formatAmount(fine)}
              size="small"
              color="error"
              variant="outlined"
              onDelete={() => onDeleteAdjustment(_id || group || "", "fines")}
            />
          )}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {formatAmount(profit, 2)} uzs
          </Typography>
        </TableCell>
        {!readonly && (
          <TableCell>
            <IconButton
              color="error"
              onClick={() => onAddAdjustment(_id || group || "", "fines")}
            >
              <GavelIcon />
            </IconButton>
            <IconButton
              color="success"
              onClick={() => onAddAdjustment(_id || group || "", "bonuses")}
            >
              <GiftIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {t("info")}
              </Typography>
              <TableContainer
                component={Paper}
                elevation={0}
                variant="outlined"
              >
                <Table aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {t("Moved amount from last month")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {t("Paid amount for current month")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {t("Paid amount for past months")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {t("Transferred amount to next month")}
                      </TableCell>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {t("Total transferred amount")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {formatAmount(movedPaymentsFromLastMonth?.total, 2)} uzs
                      </TableCell>
                      <TableCell
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {formatAmount(payments.total, 2)} uzs /{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {formatAmount(expectedAmount?.total)} uzs
                        </span>
                      </TableCell>
                      <TableCell
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {formatAmount(paidAmountForPastMonths?.total, 2)} uzs
                      </TableCell>
                      <TableCell
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {formatAmount(movedPaymentsToNextMonth?.total, 2)} uzs
                      </TableCell>
                      <TableCell
                        sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {formatAmount(transfers?.[0]?.totalTransferred, 2)} uzs
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SalaryReportRow;
