import { gql } from "@apollo/client";

export const GET_DEBTORS_BY_MONTH = gql`
  query getDebtorsByMonth($center: ID!, $teacher: ID) {
    getDebtorsByMonth(center: $center, teacher: $teacher) {
      payload {
        _id
        title
        students {
          _id
          student {
            _id
            name
            image
            phone
            extraPhone
          }
          user {
            _id
            name
            image
            phone
          }
          payments
          attendances {
            _id {
              student
              date
            }
            monthlyCharge
          }
        }
      }
    }
  }
`;
