import { useMutation, useQuery } from "@apollo/client";
import Loader from "components/Loader";
import { usePermission } from "hooks/permission";
import { useAppSelector } from "hooks/redux";
import { ISalary } from "interfaces/Salary";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import t from "translate";
import CreateSalary from "./components/CreateModal";
import UpdateSalary from "./components/UpdateModal";
import InnerLoader from "components/InnerLoader";
import PageHeader from "UI/PageHeader";
import NoData from "UI/NoData";
import { Button, Grid, Pagination } from "@mui/material";
import ConfirmDelete from "components/ConfirmModal";
import { DELETE_SALARY, GET_ALL_SALARIES } from "./api";
import SalaryCard from "./components/SalaryCard";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";

function SalaryTemplates() {
  const [selectedSalary, setSelectedSalary] = useState<ISalary>();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  const centerId = useAppSelector((store) => store.center.center?._id);

  const { PermissionComponents } = usePermission();

  const { data, loading, refetch } = useQuery(GET_ALL_SALARIES, {
    variables: {
      page: currentPage,
      size: 10,
      center: centerId,
    },
  });

  const [deleteSalary, { data: deleteData, loading: deleteLoading }] =
    useMutation(DELETE_SALARY);

  const onDelete = useCallback(() => {
    deleteSalary({
      variables: {
        salaryId: selectedSalary?._id,
        centerId,
      },
    });
  }, [centerId, deleteSalary, selectedSalary?._id]);

  const toggleCreateModalVisible = useCallback(
    () => setIsCreateModalVisible((prev) => !prev),
    []
  );

  const toggleDeleteModalVisible = useCallback(
    () => setIsDeleteModalVisible((prev) => !prev),
    []
  );

  const toggleUpdateModalVisible = useCallback(
    () => setIsUpdateModalVisible((prev) => !prev),
    []
  );

  const handleDelete = useCallback(
    (salary: ISalary) => {
      setSelectedSalary(salary);
      toggleDeleteModalVisible();
    },
    [toggleDeleteModalVisible]
  );

  const handleUpdate = useCallback(
    (salary: ISalary) => {
      setSelectedSalary(salary);
      toggleUpdateModalVisible();
    },
    [toggleUpdateModalVisible]
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!deleteLoading && deleteData) {
      toast.success(t("Salary deleted successfully!"));
      toggleDeleteModalVisible();
      refetch();
    }
  }, [deleteLoading, deleteData]);

  const salaries: ISalary[] = data?.getSalariesByCenterId?.payload ?? [];
  const totalPages = data?.getSalariesByCenterId?.totalPages ?? 1;

  return (
    <>
      {deleteLoading ? <Loader /> : null}
      <PageHeader title="salaries">
        <PermissionComponents section="salaryTemplates" action="create">
          <Button
            onClick={toggleCreateModalVisible}
            startIcon={<AddIcon />}
            variant="outlined"
          >
            {t("create salary")}
          </Button>
        </PermissionComponents>
      </PageHeader>
      {salaries.length ? (
        <>
          <Grid container spacing={2} mb={5}>
            {salaries.map((salary) => (
              <Grid item xs={12} sm={6} md={4} key={salary._id}>
                <SalaryCard
                  salary={salary}
                  handleDelete={() => handleDelete(salary)}
                  handleUpdate={() => handleUpdate(salary)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : loading ? (
        <InnerLoader />
      ) : (
        <NoData />
      )}
      <Pagination
        count={totalPages}
        onChange={(_, page) => setCurrentPage(page)}
        page={currentPage}
      />
      <ConfirmDelete
        open={isDeleteModalVisible}
        onClose={toggleDeleteModalVisible}
        onConfirm={onDelete}
      />
      <CreateSalary
        open={isCreateModalVisible}
        toggleModalVisible={toggleCreateModalVisible}
        refetch={refetch}
      />
      <UpdateSalary
        open={isUpdateModalVisible}
        toggleModalVisible={toggleUpdateModalVisible}
        salary={selectedSalary}
        refetch={refetch}
      />
    </>
  );
}

export default SalaryTemplates;
