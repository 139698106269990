import moment from "moment";
import { DebtorGroupType, DebtorStudent } from "../types";

type StudentAttendanceAndPaymentsType = {
  [month: string]: {
    [student: string]: {
      expectedAmount: number;
      paidAmount: number;
    };
  };
};
type TotalDebtorsType = {
  [month: string]: {
    expectedAmount: number;
    paidAmount: number;
  };
};

function formatStudentAttendancesAndPayments(students: DebtorStudent[]) {
  const result: StudentAttendanceAndPaymentsType = {};

  students.forEach(({ payments, attendances, _id }) => {
    Object.entries(payments || {}).forEach(([date, { paidAmount }]) => {
      const foundRecord = result[date]?.[_id];
      if (foundRecord) {
        foundRecord.paidAmount = paidAmount;
      } else if (result[date] && paidAmount >= 0) {
        result[date][_id] = {
          expectedAmount: 0,
          paidAmount: paidAmount,
        };
      } else if (paidAmount >= 0) {
        result[date] = {
          [_id]: {
            expectedAmount: 0,
            paidAmount: paidAmount,
          },
        };
      }
    });

    attendances.forEach(({ _id, monthlyCharge }) => {
      const foundRecord = result[_id.date]?.[_id.student];
      if (foundRecord) {
        foundRecord.expectedAmount = monthlyCharge;
      } else if (result[_id.date]) {
        result[_id.date][_id.student] = {
          paidAmount: 0,
          expectedAmount: monthlyCharge,
        };
      } else {
        result[_id.date] = {
          [_id.student]: {
            paidAmount: 0,
            expectedAmount: monthlyCharge,
          },
        };
      }
    });
  });

  return result;
}
export function getTotalDebtsAmount(students: DebtorStudent[] = []) {
  const result: TotalDebtorsType = {};

  students.forEach(({ payments, attendances }) => {
    Object.entries(payments || {}).forEach(([date, { paidAmount }]) => {
      const foundRecord = result[date] || { expectedAmount: 0, paidAmount: 0 };
      result[date] = {
        expectedAmount: foundRecord?.expectedAmount || 0,
        paidAmount: foundRecord?.paidAmount + paidAmount,
      };
    });

    attendances.forEach(({ _id, monthlyCharge }) => {
      const foundRecord = result[_id.date] || {
        expectedAmount: 0,
        paidAmount: 0,
      };

      result[_id.date] = {
        paidAmount: foundRecord.paidAmount,
        expectedAmount: foundRecord.expectedAmount + monthlyCharge,
      };
    });
  });

  return result;
}
export function getSummaryMonths(groups: DebtorGroupType[] = []) {
  const result: Set<string> = new Set();

  groups.forEach(({ students }) => {
    students.forEach(({ payments, attendances }) => {
      Object.keys(payments || {}).forEach((date) => {
        if (payments[date].paidAmount >= 0) {
          result.add(date);
        }
      });

      attendances.forEach(({ _id, monthlyCharge }) => {
        if (monthlyCharge > 0) {
          result.add(_id.date);
        }
      });
    });
  });

  return result;
}

export default formatStudentAttendancesAndPayments;

export const formatDebtorsMonths = (monthsString: string[]) => {
  const formattedMonths = [...monthsString];
  formattedMonths.sort((a: string, b: string) => {
    const dateA = moment(a, "MMM YYYY");
    const dateB = moment(b, "MMM YYYY");

    return dateA.diff(dateB);
  });

  const indexOfCurrentMonth = formattedMonths.indexOf(
    moment().format("MMM YYYY")
  );

  const months = formattedMonths.slice(
    0,
    indexOfCurrentMonth === -1 ? formattedMonths.length : indexOfCurrentMonth
  );
  return months;
};
