import { Box, FormControl, FormLabel, Popover, Stack } from "@mui/material";
import React from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Control, Controller, FieldValues } from "react-hook-form";
import t from "translate";

type Props = {
  name?: string;
  label?: string;
  control: Control<FieldValues, any>;
  isRequired?: boolean;
};

function ColorPicker({
  control,
  name = "color",
  label = "Color",
  isRequired,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Controller
        name={name}
        rules={{ required: isRequired }}
        control={control}
        render={({ field }) => {
          return (
            <>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="color">{t(label)}</FormLabel>
                <Stack direction="row" gap={1} my={1}>
                  <Box
                    onClick={handleClick}
                    sx={{
                      minWidth: 50,
                      height: 50,
                      borderRadius: 2,
                      bgcolor: field.value,
                      mr: 1,
                      cursor: "pointer",
                      border: "1px solid #ccc",
                    }}
                  />
                  <HexColorInput
                    {...field}
                    placeholder={t("Enter color code")}
                    style={{ width: "100%", padding: "6px 14px" }}
                  />
                </Stack>
              </FormControl>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Stack p={2}>
                  <HexColorPicker {...field} />
                </Stack>
              </Popover>
            </>
          );
        }}
      />
    </Stack>
  );
}

export default ColorPicker;
