import React, { useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import t from "translate";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { useMutation } from "@apollo/client";
import { useAppSelector } from "hooks/redux";
import { Controller, FieldValues, useForm } from "react-hook-form";

import { UPDATE_ABSENCE_REASON } from "./api";

import CloseIcon from "@mui/icons-material/Close";

import ColorPicker from "components/ColorPicker";

type Props = {
  refetch: any;
  open: boolean;
  onClose: VoidFunction;
  title: string;
  absenceReason: string;
};

function UpdateAbsenceReasonModal({
  refetch,
  onClose,
  open,
  title,
  absenceReason,
}: Props) {
  const [updateAbsenceReason, { data, loading }] = useMutation(
    UPDATE_ABSENCE_REASON
  );

  const centerId = useAppSelector((state) => state.center.center?._id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = ({ title, color }: FieldValues) => {
    updateAbsenceReason({
      variables: {
        title,
        center: centerId,
        absenceReason,
        color,
      },
    });
  };

  const handleClose = () => {
    reset({});
    onClose();
  };

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Absence reason updated successfully"));
      refetch();
      handleClose();
    }
  }, [data, loading]);

  useEffect(() => {
    reset({ title });
  }, [title]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle>{t("Update absence reason")}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Controller
            name="title"
            rules={{
              required: true,
              maxLength: 25,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    required
                    {...field}
                    value={field.value || ""}
                    label={t("Absence reason title")}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] &&
                      t("Please input the absence reason title!")
                    }
                  />
                </FormControl>
              );
            }}
          />
          <ColorPicker control={control} />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={handleClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateAbsenceReasonModal;
