import { Box, Tooltip, Typography } from "@mui/material";
import { ISidebarRoute } from "interfaces/Route";
import React from "react";
import { Link } from "react-router-dom";
import t from "translate";

type Props = {
  route: ISidebarRoute;
  pathname: string;
};

function SidebarLink({ route, pathname }: Props) {
  const { title, icon, key, path } = route;

  return (
    <li>
      <Tooltip title={t(title)} arrow placement="right">
        <Link
          className={`sidebar__link ${
            pathname === path && "sidebar__link--active"
          } ${key === "settings" ? "sidebar__link--setting" : ""}`}
          to={path}
          key={key}
        >
          <Box
            className="sidebar__link-inner"
            display="flex"
            gap={1}
            alignItems="center"
          >
            {icon}
            <Typography className="sidebar__link-text" variant="subtitle1">
              {t(title)}
            </Typography>
          </Box>
        </Link>
      </Tooltip>
    </li>
  );
}

export default SidebarLink;
